<template>
    <div class="blog-item">
        <router-link class="item" :to="`/blog/${created}-${slug}`">
            <div class="blog-banner">
                <img :src="image" :alt="`${title} image`">
            </div>
            <div class="blog-description">
                <h3>{{ title }}</h3>
                <span class="h5 date">{{ date }}</span>
            </div>
        </router-link>
    </div>
</template>
<script>
import Icon from "@/components/Icon";

export default {
    name: "BlogItem",
    components: {Icon},
    props: {
        item: {
            type: Object,
            default: null
        }
    },
    computed: {
        id() {
            return this.item?._id ?? null
        },
        title() {
            return this.item?.title ?? null
        },
        slug() {
            return this.item?.title_slug ?? null
        },
        image() {
            const img = this.item?.thumbnail?.path ?? null
            if (img == null) {
                return null
            }
            return `${process.env.VUE_APP_CMS_ROOT}${img}`
        },
        created() {
            return this.item?._created ?? 0
        },
        createdDate() {
            return new Date((this.created ?? 0) * 1000)
        },
        date() {
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
            return `${months[this.createdDate.getMonth()].substr(0, 3)}, ${this.createdDate.getFullYear()}`
        },
    }
}
</script>
<style lang="sass" scoped>
@import "src/assets/style/components/BlogItem"
</style>

