<template>
    <div id="blog">
        <div class="wrapper">
            <div class="container">
                <h1>The blog</h1>
                <div class="items">
                    <blog-item v-for="(item, i) in items" :key="i" :item="item"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BlogItem from "@/components/BlogItem";

export default {
    name: "Blog",
    components: {BlogItem},
    data: () => ({
        items: [],
    }),
    async created() {
        await this.$axios.post("collections/get/blog",
            JSON.stringify({fields: {filter: {published: true}}})
        ).then(this.onBlog).catch(this.noBlog)
    },
    methods: {
        onBlog(result) {
            this.items = result?.data?.entries ?? []
        },
        noBlog(result) {
            console.error(result)
            this.items = []
        }
    }
}
</script>
<style lang="sass" scoped>
@import "src/assets/style/views/Blog"
</style>
